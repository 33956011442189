@import "variables";

$celf-red-2: rgb(246, 200, 208);

body {
  font-family: "Gill Sans", "Gill Sans MT";
  font-size: 16px !important;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.main-celf-color {
  color: $celf-primary !important;
}
.main-celf-background {
  background-color: $celf-primary !important;
}
.alt-celf-color {
  color: $celf-secondary !important;
}
.alt-celf-color-with-opacity {
  color: $celf-secondary !important;
  opacity: 0.5;
}
.alt-celf-background {
  background-color: $celf-secondary !important;
}
.alt-celf-background-2 {
  background-color: $celf-red-2 !important;
}
.white-celf-background {
  background-color: $white !important;
}
.fester-logo-login {
  background: url("../images/logo_fester_no_margin.png") no-repeat center center;
  background-size: contain;
  width: 100%;
  height: 220px;
}
.celf-title-login {
  text-align: center;
  color: $celf-secondary;
  letter-spacing: 0.5rem;
  font-size: 2rem;
}
.celf-subtitle-login {
  text-align: center;
  color: $celf-secondary;
  letter-spacing: 0.2rem;
  border-width: 0 0 3px 0;
  border-style: solid;
  border-color: $celf-primary;
  font-size: 1.5rem;
}
.celf-footer-login {
  color: $white;
}
.navbar-brand-box {
  h4 {
    margin: 0;
    padding-top: 0.75rem;
    border-width: 0 0 1px 0;
    border-color: $celf-secondary;
    border-style: solid;
  }
  p {
    margin: 0;
    padding-top: 0.25rem;
  }
}
.btn-block {
  width: 100%;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  background-color: $white !important;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu a {
  color: $black !important;
}
